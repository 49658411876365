import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

const Page = ({children}) => {
    return (
        <Container maxWidth="sm">
            <Box py={8}>
                {children}
            </Box>
        </Container>
    )
}

export default Page;
