import { BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import HomePage from "./HomePage";
import RedeemPage from "./RedeemPage";

const App = () => {
    return (
        <Router>
            <Switch>
                <Route exact path="/">
                    <HomePage/>
                </Route>
                <Route path="/redeem/:code">
                    <RedeemPage/>
                </Route>
            </Switch>
        </Router>
    );
}

export default App;
