import Page from './Page';
import Logo from './images/logo.png'

const HomePage = () => {
    return (
        <Page>
            <img src={Logo} alt="Trails & Cocktails Logo"/>
        </Page>
    )
}

export default HomePage;
