import {useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router';
import Page from './Page';
import useTheme from '@mui/material/styles/useTheme';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import CardMedia from '@mui/material/CardMedia'
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import EmailIcon from '@mui/icons-material/Email';
import Logo from './images/logo.png'
import {ApiContext} from './Api';
import {Alert, AlertTitle, CircularProgress, Typography} from "@mui/material";

const EMAIL_RE = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const RedeemPage = () => {
    const theme = useTheme();
    const p = useParams()
    const apiUrl = useContext(ApiContext);

    const [info, setInfo] = useState({
        name: "", email: "", confirm: ""
    });
    const [isLoading, setLoading] = useState(true);
    const [isRedeeming, setRedeeming] = useState(false);
    const [isInvalid, setInvalid] = useState(false);
    const [isReady, setReady] = useState(false);
    const [isEmailInvalid, setEmailInvalid] = useState(false);
    const [isError, setError] = useState(false);

    const isDone = !!info.confirm;
    useEffect(() => {
        if (apiUrl && p.code) {
            fetch(`${apiUrl}/raffles/${p.code}`)
                .then(rsp => {
                    if (rsp.status === 404) {
                        throw new Error(rsp.statusText);
                    }
                    else return rsp.json()
                })
                .catch(() => {
                    setInvalid(true);
                })
                .finally(() => {
                    setLoading(false);
                })
        }
    }, [apiUrl, p.code]);

    useEffect(() => {
        setReady(info.email.trim().length > 0 && info.name.trim().length > 0);
    }, [info]);

    const changed = evt => {
        setInfo({
            ...info,
            [evt.target.name]: evt.target.value
        });
    }

    const redeem = () => {
        if (!EMAIL_RE.test(info.email)) {
            setReady(false);
            setEmailInvalid(true);
        }
        else {
            setRedeeming(true);
            setError(false);
            fetch(`${apiUrl}/raffles/${p.code}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(info)
            })
            .then(rsp => {
                if (rsp.status !== 200) {
                    throw new Error();
                }
                return rsp.json();
            })
            .then((data) => {
                setInfo({
                    ...info,
                    confirm: data.confirm
                });
            })
            .catch(() => {
                setError(true);
            })
            .finally(() => {
               setRedeeming(false);
            });
        }
    }

    return (
        <Page>
            {isLoading && <CircularProgress/>}
            {!isLoading && isInvalid && <Alert severity="error">
                <AlertTitle>Invalid Ticket</AlertTitle>
                <Typography>Sorry, but this ticket appears to be invalid. Please email us at <a href="mailto:contact@camba.ca">contact@camba.ca</a>.</Typography>
            </Alert> }
            {!isLoading && isError && <Alert severity="error" sx={{marginBottom: theme.spacing(1)}}>
                <AlertTitle>Oops</AlertTitle>
                <Typography>Sorry, there was an issue redeeming this ticket. Please email us at <a href="mailto:contact@camba.ca">contact@camba.ca</a>.</Typography>
            </Alert> }
            {!isLoading && isDone && <Alert severity="success">
                <AlertTitle>Thanks</AlertTitle>
                <Typography>Your ticket has been redeemed. Your confirmation code is {info.confirm}. You will be receiving an email with a confirmation shortly. Best of luck in the draw!</Typography>
            </Alert> }
            {!isLoading && !isInvalid && !isDone && <Card>
                <CardContent>
                    <Box sx={{display: 'flex'}}>
                        <CardMedia component="img" image={Logo} sx={{
                            height: 128,
                            width: 'auto'
                        }}>
                        </CardMedia>
                        <CardHeader title={"Raffle Ticket"} sx={{flexGrow: 1}} subheader="Thanks for purchasing a ticket! We just need a few details in order to redeem it."/>
                    </Box>
                    <Box component="form" py={4}>
                        <TextField label="Name" name="name" variant="outlined" required fullWidth onChange={changed}
                           InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AccountBoxIcon />
                                    </InputAdornment>
                                ),
                            }} sx={{marginBottom: theme.spacing(2)}}/>
                        <TextField label="Email" name="email" variant="outlined" required fullWidth onChange={changed}
                                   error={isEmailInvalid} helperText={isEmailInvalid?'Please provide a valid email address':''}
                           InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <EmailIcon />
                                    </InputAdornment>
                                ),
                            }}/>
                    </Box>
                </CardContent>
                <CardActions sx={{flexDirection: 'row-reverse'}}>
                    {isRedeeming ? <CircularProgress sx={{fontSize: 24}}/> :
                        <>
                            <Button size="small" disabled={!isReady} onClick={redeem}>Redeem</Button>
                            {isReady && <ArrowCircleUpIcon fontSize="large" sx={{
                                color: theme.palette.primary.main,
                                transform: 'rotate(90deg)'
                            }}/>}
                        </>
                    }
                </CardActions>
            </Card>}
        </Page>
    )
}
export default RedeemPage;
